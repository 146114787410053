.loading
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: 1fr
  position: relative
  height: 100%
  width: 100%
  z-index: 100
  align-self: center
  justify-self: center
  align-items: center
  justify-content: center
  & > div
    justify-self: center
    display: flex
  & > div svg
    width: 30px
    height: 30px
    & circle:first-child
      stroke: #767676
      stroke-width: 2px
    & circle:nth-child(2)
      fill: #767676
    animation: anim 1s linear 0s infinite
    transform-origin: center
  & > div svg.right
    animation: anim 1s linear 0s infinite reverse
    transform-origin: center



@keyframes anim
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)