@import "./md.scss";

.center {
    text-align: center;
}

.right {
    text-align: right;
}

body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: helvetica, Arial, sans-serif;
    background: #ffffff;
    min-height: 100vh;

    & a {
        text-decoration: none !important;
        color: #236ddc;

        &:hover {
            color: lighten(#236ddc, 10%);
        }
    }
    & img {
        max-width: 100%;
    }

    & h1 {
        font-size: 2rem;
    }
}
#to {
    display: block;
    min-height: 100vh;
    overflow: hidden;
    width: 100%;
    height: auto;
    transition: all 0.2s;
    justify-items: center;
}
.main {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
    --safe-area-inset-right: env(safe-area-inset-right);
    min-height: 100vh;
    // width: 100%;
    max-width: 2400px;
    position: relative;
    // justify-content: center;
    display: flex;
    flex-direction: column;
    // grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    // grid-template-rows: 1fr;
    justify-items: center;
    overflow: hidden;

    // padding: 0 10px;
    box-sizing: border-box;

    @media (display-mode: standalone) {
        --bottom: calc(50px + var(--safe-area-inset-bottom));
        padding: 0 var(--safe-area-inset-right) var(--bottom) var(--safe-area-inset-left);
    }
}
.main > section {
    max-width: 640px;
    box-sizing: border-box;
    padding: 0 10px;
    width: 100%;
    margin: 0 auto;
}

.hakkimda p:not(:first-child) {
    margin: 0 auto;
    max-width: 640px;
}

.hakkimdaFotoContainer {
    width: 200px;
    margin: 0 auto;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 30px;

    & .hakkimdaFoto {
        max-width: 200px;
    }
}
.fotograf {
    max-width: 100% !important;
}

.clickable {
    cursor: pointer;
}
