
=keyframes($name)
  @-webkit-keyframes #{$name}
    @content
  @-moz-keyframes #{$name}
    @content
  @-ms-keyframes #{$name}
    @content
  @keyframes #{$name}
    @content
=transition($transition...)
  -moz-transition: $transition
  -o-transition: $transition
  -webkit-transition: $transition
  transition: $transition
=transition-property($property...)
  -moz-transition-property: $property
  -o-transition-property: $property
  -webkit-transition-property: $property
  transition-property: $property
=transition-duration($duration...)
  -moz-transition-property: $duration
  -o-transition-property: $duration
  -webkit-transition-property: $duration
  transition-property: $duration
=transition-timing-function($timing...)
  -moz-transition-timing-function: $timing
  -o-transition-timing-function: $timing
  -webkit-transition-timing-function: $timing
  transition-timing-function: $timing
=transition-delay($delay...)
  -moz-transition-delay: $delay
  -o-transition-delay: $delay
  -webkit-transition-delay: $delay
  transition-delay: $delay
=transform($transforms)
  -moz-transform: $transforms
  -o-transform: $transforms
  -ms-transform: $transforms
  -webkit-transform: $transforms
  transform: $transforms
=transform-origin($origin)
  moz-transform-origin: $origin
  -o-transform-origin: $origin
  -ms-transform-origin: $origin
  -webkit-transform-origin: $origin
  transform-origin: $origin

$bosluk: 17.5%
$yukseklik: 10%
$EnBoy: 33px
$BarColor: #666666
$BarBorderRadius: 1px

.menuBtn
  top: calc(env(safe-area-inset-top) + 8.5px)
  align-self: center
  position: fixed
  margin: 0px auto
  cursor: pointer
  width: $EnBoy
  min-width: 30px !important
  height: $EnBoy
  min-height: 30px !important
  display: block
  border-radius: 30%
  overflow: hidden
  right: 15px
  z-index: 100
  border: 0px solid transparent
  box-shadow: 0 0 3px transparent
  transform: scale(0.8)
  // background: red
  &:hover
    box-shadow: 0 0 1px transparent
  .bar
    position: absolute
    height: $yukseklik
    width: 80%
    background: $BarColor
    border-radius: $BarBorderRadius
    left: 10%
    transition: 0.17s
    &:nth-child(1)
      margin-top: $bosluk
    &:nth-child(2)
      margin-top: ($bosluk * 2) + $yukseklik
      opacity: 1
    &:nth-child(3)
      margin-top: (3 * $bosluk) + ($yukseklik * 2)
.kapat
  .bar
    &:nth-child(1)
      transition: margin-top 0.15s,transform 0.17s 0.2s
      margin-top: ($bosluk * 2) + $yukseklik
      transform: rotate(45deg)
    &:nth-child(2)
      transition-delay: 0.1s
      transition-duration: 0
      opacity: 0
    &:nth-child(3)
      transition: margin-top 0.15s,transform 0.17s 0.2s
      margin-top: ($bosluk * 2) + $yukseklik
      transform: rotate(-45deg)
.kapaniyor
  .bar
    &:nth-child(1)
      margin-top: ($bosluk * 2) + $yukseklik
      margin-left: 0
      padding: 0
      transform: rotate(0)
      width: 80%
    &:nth-child(2)
      transition-delay: 0.2s
      opacity: 1
    &:nth-child(3)
      margin-top: ($bosluk * 2) + $yukseklik
      margin-left: 0
      padding: 0
      width: 80%
      transform: rotate(0)
