$statusBarColor: unquote('#767676')//db

#statusbar
  background: $statusBarColor
  --safe-area-inset-top: env(safe-area-inset-top)
  position: fixed
  top: 0
  z-index: 99999999
  height: var(--safe-area-inset-top)
  width: 100%

#statusbarBack
  height: env(safe-area-inset-top)
  display: block
  width: 100%
  background: rgba(255,0,0,0.7)