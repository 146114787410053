#bricks {
    --padding: 10px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    & .bricks-column {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        text-align: center;

        &:first-child .bricks-item-container {
            padding-left: calc(var(--padding) - 10px);
        }
        &:last-child .bricks-item-container {
            padding-right: calc(var(--padding) - 10px);
        }
        & .bricks-item-container {
            padding: 0 calc(var(--padding) / 2);
            display: grid;
            margin-bottom: 10px;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr;
            transition: transform 0.2s, opacity 0.2s;
            &:active,
            &:hover {
                transform: scale(1.02);
                transition: transform 0.2s, opacity 0.2s;
                transform-origin: center center;
            }
            & .bricks-item {
                width: 100%;
                grid-column: 1;
                grid-row: 1;
                justify-self: center;
                display: flex;
                flex-direction: column;
                justify-items: center;
                align-items: center;
                box-sizing: border-box;
                border: 0px solid transparent;
                border-radius: 8px;
                overflow: hidden;
                opacity: 1;
                box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
            }
        }
    }
}
