$mainTextColor: #767676
$statusBarColor: #767676
$hoverTextColor: #666666
$iphoneXStatusBarHeight: 44px
$iphoneStatusBarHeight: 20px
$headerHeight: 50px
$headerBackgroundColor: rgba(255, 255, 255, .95)

header
    --safe-area-inset-top: env(safe-area-inset-top)

    @media(display-mode: standalone)
        // height: var(--safe-area-inset-top)
        min-height: var(--safe-area-inset-top)

    z-index: 9999
    position: relative
    top: 0px
    display: grid
    grid-template-columns: 1fr !important
    grid-template-rows: var(--safe-area-inset-top) $headerHeight
    width: 100%
    height: calc(var(--safe-area-inset-top) + 50px)
    min-height: $headerHeight
    font-size: .9rem

    &.full #menu
        // background: rgba(255, 255, 255, 0.8)

    &.mobil #menu
        // background: rgba(255, 255, 255, 0.8)

    & #menu
        background: $headerBackgroundColor
        // @media(display-mode: standalone)
        //     background: transparent

        width: 100%
        display: grid
        justify-content: space-between
        position: fixed
        align-items: center
        --safe-area-inset-top: env(safe-area-inset-top)
        top: var(--safe-area-inset-top)
        min-height: $headerHeight
        grid-template-columns: 70px 2fr
        grid-template-rows: $headerHeight
        grid-template-areas: "backButton links"
        & #links
            grid-area: links
            display: flex
            justify-content: flex-end
            & a
                margin: 0 5px
                color: $mainTextColor
                &:hover
                    color: $hoverTextColor

    & .backButton
        grid-area: backButton
        margin: 0
        font-size: inherit
        line-height: auto
        padding: 0
        background: none
        border: none
        cursor: pointer
        display: flex
        align-items: center
        width: auto
        &:hover
            & span, & svg
                color: $hoverTextColor

        & span
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden
            color: $mainTextColor

        & svg
            padding: 0 5px 0 15px
            height: 30px
            width: 10px !important
            color: $mainTextColor

    #menu #menudiv
        display: flex
        flex-direction: row
        align-self: center
        min-width: 100px
        justify-self: center
        grid-area: menu

        a
            align-self: center
            text-decoration: none
            color: $mainTextColor
            margin: auto
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            background-image: linear-gradient(to right, $hoverTextColor, $hoverTextColor 50%, $mainTextColor 50%)
            background-size: 200% 100%
            background-position: 100%

            &:hover
                color: $hoverTextColor
                transition: all 0.5s cubic-bezier(0, 0, 0.23, 1)
                background-position: 0%

            &.active
                color: $hoverTextColor
                background-position: 0%
