$BG: linear-gradient(rgba(255, 255, 255, 0.95) 50px, rgba(255, 255, 255, 0.95) 50px)

#menuDrawer
    --safe-area-inset-top: env(safe-area-inset-top)
    position: fixed
    display: grid
    top: 0
    left: -200px
    min-width: 150px
    width: auto
    height: 100vh
    z-index: 98
    background-image: $BG
    // background-color: #fafafa
    transition: all 0.2s linear

    &.open
        left: 0

    &.closing
        left: -200px

#drawerMenu
    justify-self: center
    align-self: center

    & .active
        color: #666666
        border-left: 1px solid rgba(0, 0, 0, 0.9)

    & a:hover
        color: #666666

    & a
        display: block

        color: #767676
        box-sizing: border-box
        padding: 10px
        text-align: left
        border-left: 1px solid rgba(0, 0, 0, 0.5)
        margin-top: 5px !important
