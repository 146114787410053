footer
    height: 100px

    // background: rgba(244, 244, 244, 0.8)
    margin-top: 20px
    width: 100%
    display: flex
    align-self: end
    border-top: 1px solid #efefef

    #copyright
        background: inherit
        font-size: 0.8em
        color: #767676
        font-weight: 100
        align-self: center
        margin: auto
