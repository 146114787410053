.signitureBox
  border-top: 1px solid #eeeeee
  border-bottom: 1px solid #eeeeee
  display: grid
  grid-template-columns: 50px auto
  grid-template-rows: 1fr
  padding: 20px 5px
  margin: 50px 0
  grid-template-areas: "icon info"
  & .signitureBoxAvatar
    grid-area: icon
    height: 50px
    box-sizing: border-box
    border: 1px solid #eeeeee
    border-radius: 50%
    padding: 3px
    width: 100%
    display: grid
    justify-items: center
    align-items: center
    & img
      border-radius: 50%
  & .signitureBoxInfo
    grid-area: info
    display: grid
    padding-left: 10px
    grid-template-columns: auto auto
    grid-template-rows: 1fr 1fr
    grid-template-areas: "title social" "detail social"
    & .signitureBoxInfoName
      align-self: end
      grid-area: title
      font-weight: bold
      font-size: 1em
      color: #333333
      letter-spacing: 1px
    & .signitureBoxInfoTitle
      align-self: start
      grid-area: detail
      font-size: 0.7em
      line-height: 1.6
      color: #666666
