.photoContainer
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: 1fr
    cursor: pointer
    width: 100%
    border-radius: 7px
    overflow: hidden

    // transition: all 1s

    & picture
        width: 100%
        display: flex
        flex-direction: column
        grid-row: 1
        grid-column: 1
        background-color: black

    & p
        opacity: 0
        color: white
        position: relative
        display: grid
        align-self: flex-end
        grid-row: 1
        grid-column: 1
        margin: 0
        height: 0
        align-items: center
        box-sizing: border-box
        background: rgba(0, 0, 0, 0.3)
        transition: height 0.2s

    &:hover p
        opacity: 1
        padding: 5px 0
        height: auto
