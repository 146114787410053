.containerBox {
    border: 1px solid transparent;
    border-radius: 5px;
    display: grid;
    grid-template-columns: var(--box-size) auto;
    grid-template-rows: 1fr;
    padding: 5px 5px 10px 5px;
    margin-bottom: 20px;
    grid-template-areas: "icon info";
}

.containerBox:hover {
    border: 1px solid #eeeeee;
}

.containerBox.noImg {
    cursor: pointer;
    grid-template-areas: "info info";
    border-radius: 0;
    border-bottom: 1px solid #eeeeee;
}

.containerBox.noImg:hover {
    border-radius: 5px;
}

.containerBox.noImg:hover .boxInfo .boxInfoTitle {
    color: #5694f1;
}

.containerBox.noImg .boxInfo {
    padding-left: 0;
}

.containerBox.noDate {
    margin-bottom: 50px;
}

.containerBox.noDate .boxInfo {
    grid-template-areas: "title title" "detail detail";
}

.containerBox .boxIcon {
    grid-area: icon;
    height: var(--box-size);
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
}

.containerBox .boxIcon img,
.containerBox .boxIcon picture {
    height: var(--box-size);
    border-radius: 50%;
}

.containerBox .boxIcon.square img {
    border-radius: 0;
}

.containerBox .boxInfo {
    grid-area: info;
    display: grid;
    padding-left: 20px;
    grid-template-columns: 1fr 135px;
    grid-template-rows: auto auto;
    grid-template-areas: "title date" "detail detail";
}

@media (max-width: 400px) {
    .containerBox .boxInfo {
        grid-template-rows: auto auto auto;
        grid-template-areas: "title title" "date date" "detail detail";
    }
}

.containerBox .boxInfo .boxInfoDate {
    grid-area: date;
    display: flex;
    justify-self: right;
    align-items: flex-start;
    position: relative;
    color: #767676;
    font: 300 1em / 1.8 "Helvetica Neue", helvetica, Arial, sans-serif;
}

@media (max-width: 400px) {
    .containerBox .boxInfo .boxInfoDate {
        justify-self: left;
    }
}

.containerBox .boxInfo .boxInfoDate:hover {
    color: #767676;
}

.containerBox .boxInfo .boxInfoTitle {
    grid-area: title;
    line-height: 1.4;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.4rem;
    color: #333333;
    letter-spacing: 1px;
}

.containerBox .boxInfo .boxInfoDetail {
    grid-area: detail;
    font-size: 1rem;
    line-height: 1.2;
    color: #666666;
}
