.head-container {
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    max-width: 640px;
    width: 100%;

    .head-name {
        color: #222;
        letter-spacing: -2px;
        font-size: 2.5em;
        font-weight: 600;
        margin: 3px;
        text-align: center;
    }

    .head-title {
        letter-spacing: -0.5px;
        margin: 0;
        text-align: center;
    }

    & a {
        justify-self: center;
        width: 80px;
        height: 80px;
        margin: 0 auto;
        border-radius: 50%;
        overflow: hidden;

        @media (display-mode: standalone) {
            margin: 1em auto 0;
        }
    }

    & h2 {
        font-size: 16px;
        font-weight: 100;
        text-align: center;
        color: #767676;
        max-width: 100% !important;
        & a,
        & span {
            color: #236ddc;
        }
    }

    & hr {
        height: 1px;
        border: none;
        border-color: #eeeeee;
        color: #eeeeee;
        background: #eeeeee;
        width: 100%;
        margin: 50px 0 0 0;
    }
}
