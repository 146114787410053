$mainTextColor: #666666
$hoverTextColor: #767676
$height: 50px
$BG: rgba(244, 244, 244, 0.95)

#footerMob
  --safe-area-inset-bottom: env(safe-area-inset-bottom)
  background: $BG
  position: fixed
  bottom: 0
  width: 100%
  padding-bottom: var(--safe-area-inset-bottom)
  height: $height
  z-index: 9999
  #footerMenuMob
    display: flex
    height: 100%
    width: 100%
    a
      height: 100%
      width: 100%
      cursor: pointer
      text-decoration: none
      .menuItem
        display: grid
        grid-template-columns: 1fr
        grid-template-rows: 1.4fr 1fr
        height: 100%
        width: 100%
        & svg
          justify-self: center
          align-self: end
          font-size: 1.4rem
          color: $hoverTextColor
        & span
          justify-self: center
          padding-top: 5px
          align-self: start
          font-size: 0.5rem
          -webkit-background-clip: text
          -webkit-text-fill-color: transparent
          background-image: linear-gradient(to right,$mainTextColor,$mainTextColor 50%,$hoverTextColor 50%)
          background-size: 200% 100%
          background-position: 100%
      &:hover .menuItem span
        color: $hoverTextColor
        transition: all 0.2s cubic-bezier(0.000, 0.000, 0.230, 1)
        background-position: 0%
      &.active .menuItem span
        color: $hoverTextColor
        background-position: 0%
      &:hover .menuItem svg
        color: $mainTextColor
      &.active .menuItem svg
        color: $mainTextColor
