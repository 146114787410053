/* Markdown THEME */

.hljs,
pre {
    color: #f2f2f2;
    display: block;
    border-radius: 5px;
    overflow-x: auto;
    padding: 0.5em;
    background: #282a36;
    box-sizing: border-box;
}
.hljs-built_in,
.hljs-selector-tag,
.hljs-section,
.hljs-link {
    // color: #8be9fd;
    color: #00f593;
}

.hljs-keyword {
    // color: #ff79c6;
    color: #278eff;
}

.hljs,
.hljs-subst {
    color: #f8f8f2;
}

.hljs-title {
    color: #50fa7b;
}

.hljs-string,
.hljs-meta,
.hljs-name,
.hljs-type,
.hljs-attr,
.hljs-symbol,
.hljs-bullet,
.hljs-addition,
.hljs-variable,
.hljs-template-tag,
.hljs-template-variable {
    color: #f1fa8c;
}

.hljs-comment,
.hljs-quote,
.hljs-deletion {
    color: #6272a4;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-title,
.hljs-section,
.hljs-doctag,
.hljs-type,
.hljs-name,
.hljs-strong {
    font-weight: bold;
}

.hljs-literal,
.hljs-number {
    color: #bd93f9;
}

.hljs-emphasis {
    font-style: italic;
}

.md {
    max-width: 100%;
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    span,
    table {
        font-weight: 300;
    }
} //
blockquote {
    border-left: 5px solid #ddd;
    margin-left: 0;
    padding-left: 40px;
    box-sizing: border-box;
    color: #8c8c8c;
}

/* table */
table {
    border-collapse: collapse;
    & td,
    th {
        border: 1px solid #dfe2e5;
        padding: 5px 10px;
    }
    & tr {
        background: #fff;
    }
    & tr:nth-child(even) {
        background: #f6f8fa;
    }
}
